import Loader from '@lepass/components/utility/loader'
import {AuthAction, init} from 'next-firebase-auth'
import serviceAccount from '../config/serviceAccount.json'
import firebaseConfig from '../config/firebase.config'

const initAuth = () => {
  init({
    authPageURL: '/signin',
    appPageURL: '/dashboard',
    loginAPIEndpoint: '/api/login', // required
    logoutAPIEndpoint: '/api/logout', // required
    // Required in most cases.
    firebaseAdminInitConfig: {
      credential: serviceAccount,
      databaseURL: firebaseConfig.databaseURL,
    },
    firebaseClientInitConfig: firebaseConfig,
    cookies: {
      name: 'le-pass', // required
      // Keys are required unless you set `signed` to `false`.
      // The keys cannot be accessible on the client side.
      keys: [
        typeof window === 'undefined' ? 'Bw?+4g4Tb"G^rr6V' : undefined,
        typeof window === 'undefined' ? '>+w3rcUuQhN4Je7s' : undefined,
      ],
      httpOnly: true,
      maxAge: 2 * 60 * 60 * 24 * 1000, // 2 days
      overwrite: true,
      path: '/',
      sameSite: 'strict',
      secure: process.env.NODE_ENV === 'production', // set this to false in local (non-HTTPS) development
      signed: true,
    },
  })
}

export default initAuth

const protectedPageConfig = {
  ssr: {
    whenUnauthed: AuthAction.SHOW_LOADER,
  },
  default: {
    whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
    whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
    LoaderComponent: Loader,
  }
}

const publicPageConfig = {
  ssr: {
    whenAuthed: AuthAction.SHOW_LOADER,
  },
  default: {
    whenAuthed: AuthAction.REDIRECT_TO_APP,
  }
}

export {publicPageConfig, protectedPageConfig}
